import ApiService from "@/core/services/ApiService";
import CookieService from "@/core/services/CookieService";

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  surname: string;
  email: string;
  password: string;
  type: string;
}

export interface AuthStateType {
  errors: unknown;
  user: User | null;
  isAuthenticated: boolean;
  userType: string;
  permissions: Set<string>;
  baseApi: string;
  b2bCredits: number;
}

const AuthModule = {
  state: {
    errors: {},
    user: null,
    isAuthenticated: false,
    baseApi: "",
    userType: "",
    permissions: new Set(),
    b2bCredits: 0,
  } as AuthStateType,
  getters: {
    currentUser(state): User {
      return state.user;
    },
    isUserAuthenticated(state): boolean {
      return state.isAuthenticated;
    },
    getErrors(state) {
      return state.errors;
    },
  },
  mutations: {
    setError(state, errors) {
      console.log("[called]", errors);
      state.errors = errors;
    },
    setUser(state, user) {
      state.user = user;
    },
    setAuth(state, user) {
      state.isAuthenticated = true;
      state.user = user;
      state.errors = {};
      state.userType = user.roles.find((role) => role.name === "admin")
        ? "admin"
        : "agency-admin";

      const permissions: string[] = [];

      user.roles.forEach(({ name, permissions: loopPermissions }) => {
        if (!["agency", "user"].includes(name)) {
          loopPermissions.forEach(({ name }) => {
            permissions.push(name);
          });
        }
      });

      state.permissions = new Set(permissions);
    },
    logOut(state) {
      state.isAuthenticated = false;
      state.user = {};
      state.errors = [];
      CookieService.reset();
    },
    setB2bCredits(state, credits) {
      state.b2bCredits = credits;
    },
  },
  actions: {
    login({ commit }, credentials) {
      return ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          commit("setError", response.data.errors);
          return response;
        });
    },
    logout({ commit }) {
      return ApiService.post("auth/logout").then(() => {
        commit("logOut");
      });
    },
    setB2bCredits({ commit }, credits) {
      commit("setB2bCredits", credits);
    },
    getBaseApi({ state }) {
      const baseApi = state.baseApi || localStorage.getItem("base_api");

      return ["ADMIN_API", "B2B_API"].includes(baseApi) ? baseApi : "B2B_API";
    },
    async verifyAuth({ state, commit, dispatch }) {
      if (!CookieService.get("XSRF-TOKEN")) {
        return Promise.resolve(false);
      }

      ApiService.setApiType(await dispatch("getBaseApi"));

      if (!state.user) {
        const user = await ApiService.get("user")
          .then((res) => res.data.data)
          .catch(() => false);

        if (!user) {
          return Promise.resolve(false);
        }

        commit("setAuth", user);
      }

      return Promise.resolve(true);
    },
    setAuth({ commit }, user: User) {
      commit("setAuth", user);
    },
    checkPermission({ state }, permissions: string[] = []) {
      return permissions.some((permission) =>
        state.permissions.has(permission)
      );
    },
  },
};

export default AuthModule;
