import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import CookieService from "@/core/services/CookieService";

type BASE_TYPE = "ADMIN_API" | "B2B_API" | "WEB_API";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  public static ADMIN_API = process.env.VUE_APP_API_URL + "/api/v1/admin/";
  public static B2B_API = process.env.VUE_APP_API_URL + "/api/v1/b2b/";
  public static WEB_API = process.env.VUE_APP_API_URL + "/api/v1/";
  public static BASE_TYPE: BASE_TYPE;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = ApiService.ADMIN_API;
    ApiService.vueInstance.axios.defaults.withCredentials = true;
  }

  public static setApiType(type: BASE_TYPE) {
    ApiService.vueInstance.axios.defaults.baseURL = this[type];
    this.BASE_TYPE = type;
    localStorage.setItem("base_api", type);
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common["X-XSRF-TOKEN"] =
      CookieService.get("XSRF-TOKEN");
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource
   * @param data
   * @param config
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    data = {},
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, data, config);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param data
   * @param config
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    data: any,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, data, config);
  }

  /**
   * @description Send the Patch HTTP request
   * @param resource
   * @param data
   * @param config
   * @returns Promise<AxiosResponse>
   */
  public static patch(
    resource: string,
    data: any,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}`, data, config);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
