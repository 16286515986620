export interface PageFilterItem {
  label: string;
  action: () => void;
}

export interface PageStateType {
  title: string;
  filters: PageFilterItem[];
}

const PageModule = {
  state: {
    title: "",
    filters: [],
  } as PageStateType,
  getters: {},
  mutations: {
    setTitle(state, title: string) {
      state.title = title;
    },
    setFilters(state, filters: PageFilterItem[]) {
      state.filters = filters;
    },
    clearFilters(state) {
      state.filters = [];
    },
  },
  actions: {
    setTitle({ commit }, title: string) {
      return commit("setTitle", title);
    },
    clearTitle({ commit }) {
      return commit("setTitle", "");
    },
    setFilters({ commit }, filters: PageFilterItem[]) {
      return commit("setFilters", filters);
    },
    clearFilters({ commit }) {
      return commit("clearFilters");
    },
  },
};

export default PageModule;
