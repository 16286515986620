import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.VUE_APP_API_URL as string;

const get = function (name: string) {
  return Cookies.get(name);
};

const set = function (key: string, value: string, options: object | undefined) {
  Cookies.set(key, value, options);
};

const remove = function (key: string) {
  Cookies.remove(key);
};

const reset = function () {
  localStorage.removeItem("base_api");
  // remove("travatasweb_session");
  // remove("XSRF-TOKEN");
};

const setCSRF = function () {
  axios.defaults.withCredentials = true;
  return axios({
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    url: `${baseUrl}/sanctum/csrf-cookie`,
  });
};

export default { get, set, remove, reset, setCSRF };
