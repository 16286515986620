import {
  createRouter,
  createWebHashHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import CookieService from "@/core/services/CookieService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/flight-search",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
      permissions: [],
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
          agencyAllowed: true,
          permissions: [],
        },
      },
      {
        path: "customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/crafted/widgets/CustomerList.vue"),
        meta: {
          pageTitle: "Customers Listing",
          breadcrumbs: ["Apps", "Customers"],
          permissions: ["customers"],
        },
      },
      {
        path: "visa-applications",
        name: "apps-visa-applications",
        component: () => import("@/views/crafted/widgets/VisaApplications.vue"),
        meta: {
          pageTitle: "Visa Applications",
          breadcrumbs: ["Apps", "Customers"],
          permissions: ["visa-applications"],
        },
      },
      {
        path: "visa-guidelines",
        name: "apps-visa-guidelines",
        component: () => import("@/views/crafted/widgets/VisaGuidelines.vue"),
        meta: {
          pageTitle: "Visa Guidelines",
          breadcrumbs: ["Apps", "Customers"],
          permissions: ["visa-guidelines"],
        },
      },
      {
        path: "general-payments",
        name: "apps-general-payments",
        component: () => import("@/views/crafted/widgets/GeneralCredits.vue"),
        meta: {
          pageTitle: "General Payments",
          breadcrumbs: ["Apps", "Customers"],
          permissions: ["payments"],
        },
      },
      {
        path: "b2b-payments",
        name: "apps-b2b-payments",
        component: () => import("@/components/widgets/tables/CreditsTable.vue"),
        meta: {
          pageTitle: "B2B Payments",
          breadcrumbs: ["Apps", "Customers"],
          permissions: ["payments"],
        },
      },
      {
        path: "agency-list",
        name: "agency-list",
        component: () => import("@/components/widgets/tables/AgencyTable.vue"),
        meta: {
          pageTitle: "Agency List",
          breadcrumbs: ["Apps", "Customers"],
          permissions: ["b2b-agency-list"],
        },
      },
      {
        path: "flight-search",
        name: "flight-search",
        component: () => import("@/views/crafted/widgets/FlightSearchView.vue"),
        meta: {
          pageTitle: "Flight search",
          breadcrumbs: ["Apps", "B2B"],
          agencyAllowed: true,
          permissions: ["flight-search"],
        },
      },
      {
        path: "flight-booking",
        name: "flight-booking",
        component: () =>
          import("@/views/crafted/widgets/FlightBookingView.vue"),
        meta: {
          pageTitle: "Flight search",
          breadcrumbs: ["Apps", "B2B"],
          agencyAllowed: true,
          permissions: ["flight-search"],
        },
      },
      {
        path: "agency-booking-list",
        name: "agency-booking-list",
        component: () =>
          import("@/views/crafted/widgets/AgencyBookingList.vue"),
        meta: {
          pageTitle: "Agency booking list",
          breadcrumbs: ["Apps", "B2B"],
          permissions: ["booking", "flights-bookings"],
        },
      },
      {
        path: "ticket-requests",
        name: "ticket-requests",
        component: () =>
          import("@/views/crafted/widgets/AgencyTicketRequestsList.vue"),
        meta: {
          pageTitle: "Ticket requests",
          breadcrumbs: ["Apps", "B2B"],
          agencyAllowed: true,
          permissions: ["ticket-requests", "b2b-ticket-requests"],
        },
      },
      {
        path: "business-travellers",
        name: "business-travellers",
        component: () =>
          import("@/components/widgets/tables/TravellersTable.vue"),
        meta: {
          pageTitle: "Travellers",
          breadcrumbs: ["Apps", "B2B"],
          agencyAllowed: true,
          permissions: ["travellers"],
        },
      },
      {
        path: "credits",
        name: "credits",
        component: () => import("@/components/widgets/tables/CreditsTable.vue"),
        meta: {
          pageTitle: "Credits",
          breadcrumbs: ["Apps", "B2B"],
          agencyAllowed: true,
          permissions: ["payments"],
        },
      },
      {
        path: "b2b-payments/credits",
        name: "b2b-credits",
        component: () => import("@/components/widgets/tables/CreditsTable.vue"),
        meta: {
          pageTitle: "Credits",
          breadcrumbs: ["Apps", "B2B"],
          agencyAllowed: true,
          permissions: ["payments"],
        },
      },
      {
        path: "b2b-payments/accounts",
        name: "b2b-payment-accounts",
        component: () =>
          import("@/components/widgets/tables/PaymentAccountTable.vue"),
        meta: {
          pageTitle: "Payment accounts",
          breadcrumbs: ["Apps", "B2B"],
          agencyAllowed: true,
          permissions: ["payments"],
        },
      },
      {
        path: "agency-profile",
        name: "agency-profile",
        component: () =>
          import("@/components/widgets/tables/AgencyProfile.vue"),
        meta: {
          pageTitle: "Agency Profile",
          breadcrumbs: ["Apps", "B2B"],
          agencyAllowed: true,
          permissions: ["agency-profile"],
        },
      },
      {
        path: "payment-accounts",
        name: "payment-accounts",
        component: () =>
          import("@/components/widgets/tables/PaymentAccountTable.vue"),
        meta: {
          pageTitle: "Payments accounts",
          breadcrumbs: ["Apps", "Admin"],
          permissions: ["payment-accounts"],
        },
      },
      {
        path: "promo-list",
        name: "apps-promo-list",
        component: () => import("@/views/crafted/widgets/PromoList.vue"),
        meta: {
          pageTitle: "Promo List",
          breadcrumbs: ["Apps", "Customers"],
          permissions: ["promo"],
        },
      },
      {
        path: "package-list",
        name: "apps-package-list",
        component: () => import("@/views/crafted/widgets/PackageList.vue"),
        meta: {
          pageTitle: "Package List",
          breadcrumbs: ["Packages"],
          permissions: ["packages"],
        },
      },
      {
        path: "provider-list",
        name: "apps-provider-list",
        component: () =>
          import("@/components/widgets/tables/ProvidersTable.vue"),
        meta: {
          pageTitle: "Providers List",
          breadcrumbs: ["Providers"],
          permissions: ["providers"],
        },
      },
      {
        path: "airport-list",
        name: "apps-airport-list",
        component: () => import("@/views/crafted/widgets/AirportList.vue"),
        meta: {
          pageTitle: "Airport List",
          breadcrumbs: ["Apps", "Customers"],
          permissions: ["airports"],
        },
      },
      {
        path: "airline-commissions",
        name: "apps-airline-commissions",
        component: () =>
          import("@/views/crafted/widgets/AirlineCommissionList.vue"),
        meta: {
          pageTitle: "Airline commissions",
          breadcrumbs: ["Apps", "Airline"],
          agencyAllowed: true,
          permissions: ["airline-commissioners"],
        },
      },
      {
        path: "airline-markups",
        name: "apps-markups",
        component: () =>
          import("@/views/crafted/widgets/AirlineMarkupList.vue"),
        meta: {
          pageTitle: "Markups",
          breadcrumbs: ["Apps", "Markups"],
          agencyAllowed: true,
          permissions: ["markups"],
        },
      },
      {
        path: "airlines-list",
        name: "apps-airlines-list",
        component: () => import("@/views/crafted/widgets/AirlineList.vue"),
        meta: {
          pageTitle: "Airlines List",
          breadcrumbs: ["Apps", "Customers"],
          permissions: ["airlines"],
        },
      },
      {
        path: "airline-restrictions",
        name: "apps-airline-restrictions",
        component: () =>
          import("@/components/widgets/tables/AirlineRestrictionsTable.vue"),
        meta: {
          pageTitle: "Airline restrictions",
          breadcrumbs: ["Airline", "Restrictions"],
          permissions: ["airline-restrictions"],
        },
      },
      {
        path: "charges-list",
        name: "apps-charges-list",
        component: () => import("@/views/crafted/widgets/ChargesList.vue"),
        meta: {
          pageTitle: "Charges List",
          breadcrumbs: ["Apps", "Customers"],
          permissions: ["charges"],
        },
      },
      {
        path: "flight-presets",
        name: "apps-flight-presets",
        component: () => import("@/views/crafted/widgets/FlightPresetList.vue"),
        meta: {
          pageTitle: "Flight presets",
          breadcrumbs: ["Apps", "Customers"],
          permissions: ["flight-search-presets"],
        },
      },
      {
        path: "subscribers",
        name: "apps-subscribers",
        component: () => import("@/views/crafted/widgets/SubscriberList.vue"),
        meta: {
          pageTitle: "Subscribers",
          breadcrumbs: ["Apps", "Customers"],
          permissions: ["subscribers"],
        },
      },
      {
        path: "/booking/flights",
        name: "booking-list",
        component: () => import("@/views/crafted/widgets/BookingList.vue"),
        meta: {
          pageTitle: "Flight Bookings",
          breadcrumbs: ["Crafted", "Widgets"],
          agencyAllowed: true,
          permissions: ["booking", "flights-bookings"],
        },
      },
      {
        path: "/booking/packages",
        name: "package-list",
        component: () =>
          import("@/views/crafted/widgets/PackageBookingList.vue"),
        meta: {
          pageTitle: "Package Bookings",
          breadcrumbs: ["Crafted", "Widgets"],
          permissions: ["packages-bookings"],
        },
      },
      {
        path: "/booking-view/:booking_id",
        name: "booking-view",
        component: () => import("@/views/crafted/widgets/BookingView.vue"),
        meta: {
          pageTitle: "Flight Booking Details",
          agencyAllowed: true,
          permissions: ["flights-bookings", "booking"],
        },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/components/widgets/tables/UsersTable.vue"),
        meta: {
          pageTitle: "Users",
          agencyAllowed: true,
          permissions: ["users"],
        },
      },
      {
        path: "/users/roles",
        name: "roles",
        component: () => import("@/components/widgets/tables/RolesTable.vue"),
        meta: {
          pageTitle: "Roles",
          agencyAllowed: true,
          permissions: ["users"],
        },
      },
      {
        path: "/users/roles/permissions",
        name: "permissions",
        component: () =>
          import("@/components/widgets/tables/PermissionsTable.vue"),
        meta: {
          pageTitle: "Permissions",
          agencyAllowed: true,
          permissions: ["users"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
          permissions: [],
        },
      },
      {
        path: "/sign-in-admin",
        name: "sign-in-admin",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In Admin",
          permissions: [],
        },
      },
      // {
      //   path: "/password-reset",
      //   name: "password-reset",
      //   component: () =>
      //     import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      //   meta: {
      //     pageTitle: "Password reset",
      //   },
      // },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
          permissions: [],
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
          permissions: [],
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    const auth = await store
      .dispatch("verifyAuth")
      .then((auth) => auth)
      .catch(() => false);

    if (auth) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isOpen = to.meta.permissions.length === 0;
      const authorised = isOpen
        ? true
        : await store.dispatch("checkPermission", to.meta.permissions);

      if (authorised) {
        next();
      } else {
        next({ name: "dashboard" });
      }
    } else {
      const name =
        localStorage.getItem("base_api") === "ADMIN_API"
          ? "sign-in-admin"
          : "sign-in";
      CookieService.reset();
      next({ name });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
